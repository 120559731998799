/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import find from "lodash/find";

import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import Paper from "@material-ui/core/Paper";

import { toggleChip } from "../../actions/headlines";
import { themeColors } from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    color: themeColors.textBody,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputLabel-filled": {
      color: themeColors.textBody,
    },
    "& .MuiFormControl-fullWidth": {
      backgroundColor: themeColors.backgroundInput,
    },
    "& .MuiIconButton-root": {
      padding: "0 5px",
      background: themeColors.backgroundInput,
    },
    "& .MuiSvgIcon-root": {
      fill: themeColors.textBody,
    },
    "& .MuiInputBase-root .MuiInputBase-input.MuiAutocomplete-input": {
      height: "auto",
      color: themeColors.textBody,
      padding: "4px 5px",
      fontSize: "12px",
      maxWidth: "200px",
      "&::placeholder": {
        color: themeColors.textBody,
        textAlign: "right",
      },
    },
    "& .MuiChip-root": {
      margin: "0px",
      height: "26px",
      fontFamily: "Roboto Condensed",
    },
    "& .MuiChip-deleteIcon": {
      fill: themeColors.backgroundButton,
      height: "16px",
    },
    "& .MuiAutocomplete-popupIndicator": {
      marginRight: "-8px",
      padding: "2px 2px",
      borderRadius: "0px",
    },
    "& .MuiAutocomplete-endAdornment": {
      padding: "3px 4px 0 0",
      "& .MuiSvgIcon-root": {
        fill: themeColors.backgroundButton,
      },
    },
    "& .MuiPaper-root": {
      maxWidth: "200px",
    },
  },
  paper: {
    backgroundColor: themeColors.backgroundButton,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "200px",
    "& .MuiAutocomplete-inputRoot": {
      flexWrap: "nowrap",
      overflow: "hidden",
    },
  },
  serchIcon: {
    margin: "2px 5px 0 5px",
    display: "flex",
    height: "100%",
    "& svg": {
      height: "15px",
    },
    "& svg path": {
      fill: themeColors.backgroundInput,
    },
  },
  optionLabel: {
    width: "100%",
    fontFamily: "Roboto Condensed",
  },
  countLabel: {
    fontSize: "12px",
    display: "inline-block",
    color: "#666",
    fontFamily: "Roboto Condensed",
    float: "right",
  },
}));

function Tags(props) {
  const { chips = [], toggleChip, chipSelections = [] } = props;
  const [cachedValue, setValue] = useState(
    chips.filter((item) => chipSelections.indexOf(item.text) > -1)
  );
  const [hasChanged, setHasChanged] = useState(0);
  const [isOpened, setIsOpened] = useState(0);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        disableCloseOnSelect
        id="tags-filled"
        limitTags={2}
        options={chips}
        value={
          isOpened
            ? cachedValue
            : chips.filter((item) => chipSelections.indexOf(item.text) > -1)
        }
        getOptionLabel={(option) => option.text}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const selection = option;
            if (selection) {
              return (
                <Chip
                  variant="outlined"
                  label={`${selection.text} (${selection.count})`}
                  {...getTagProps({ index })}
                />
              );
            }
          })
        }
        onOpen={() => setIsOpened(true)}
        onChange={(event, newInputValue) => {
          if (Array.isArray(newInputValue)) {
            setValue(newInputValue);
            setHasChanged(true);
            if (newInputValue.length < chipSelections.length) {
              setValue(newInputValue);
              toggleChip(newInputValue.map((item) => item.text));
              setHasChanged(true);
            }
          } else if (
            typeof newInputValue === "string" ||
            newInputValue instanceof String
          ) {
            setValue([]);
            toggleChip([]);
          }
        }}
        onClose={(event, newInputValue) => {
          setIsOpened(false);
          if (hasChanged) {
            toggleChip(cachedValue.map((item) => item.text));
            setHasChanged(false);
          }
        }}
        renderOption={(option, i) => {
          return (
            <div className={classes.optionLabel}>
              {option.text}{" "}
              <span className={classes.countLabel}>({option.count})</span>
            </div>
          );
        }}
        renderInput={(params) => (
          <Paper className={classes.paper}>
            <div className={classes.serchIcon}>
              <SearchIcon />
            </div>
            <TextField {...params} />
          </Paper>
        )}
      />
    </div>
  );
}

const structuredSelector = createStructuredSelector({
  chips: (state) => state.headlines.chips,
  chipSelections: (state) => state.headlines.chipSelections,
});

const mapDispatchToProps = { toggleChip };
export default connect(structuredSelector, mapDispatchToProps)(Tags);
