import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import classnames from "classnames";
import * as d3 from "d3";

import moment from "moment";
import orderBy from "lodash/orderBy";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { sentimentColorRange, themeColors } from "../../constants/colors";

import Loader from "../Loader";

const rowSize = 28;

const styles = (theme) => ({
  root: {
    border: `1px solid ${themeColors.strokeTable}`,
    "& .MuiDataGrid-columnsContainer": {
      borderBottom: `1px solid ${themeColors.strokeTable}`,
    },
    "& .MuiDataGrid-cell": {
      borderBottom: `1px solid ${themeColors.strokeCell}`,
      lineHeight: `${rowSize}px !important`,
    },
    "& .MuiDataGrid-footer": {
      maxHeight: "32px",
      minHeight: "32px",
    },
    "& .MuiTablePagination-root": {
      color: themeColors.textMeta,
      fontSize: "10px",
    },
    "& .cell--age": {
      fontSize: "10px",
    },
  },
  sentimentCell: {
    width: "100%",
    textAlign: "center",
  },
  rowLink: {
    color: themeColors.textBody,
    fontWeight: 500,
    fontFamily: "Roboto Condensed, sans-serif",
    textDecoration: "none",
    fontSize: "16px",
    display: "block",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    overflow: "hidden",
    "&:hover": {
      color: themeColors.textLinkHover,
    },
  },
});

class BasicGrid extends PureComponent {
  state = {
    columns: [],
  };

  getColumns(hideScore = false) {
    const {
      classes,
      lowestSentimentArticles,
      highestSentimentArticles,
    } = this.props;
    const lowest = lowestSentimentArticles[0].sentiment;
    const highest = highestSentimentArticles[0].sentiment;
    const { clientWidth } = document.body;
    const sentimentWidth = 100;
    const scoreWidth = 130;
    const subjectivityWidth = 55;
    const ageWidth = 70;
    const padding = 20;
    const netTitleWidth =
      clientWidth -
      sentimentWidth -
      subjectivityWidth -
      ageWidth -
      scoreWidth -
      padding;
    const titleWidth = hideScore ? netTitleWidth / 2 - 40 : netTitleWidth;

    return [
      {
        field: "title",
        headerName: "Title",
        width: titleWidth,
        renderCell: (params) =>
          params &&
          params.row && (
            <a
              href={params.row.url}
              className={classes.rowLink}
              target="_blank"
              title={params.row.title}
              rel="noreferrer"
            >
              {params.row.title}
            </a>
          ),
      },
      {
        field: "score",
        hide: hideScore,
        headerName: "Score / Comments",
        width: scoreWidth,
        renderCell: (params) =>
          params && params.row && params.row.score ? (
            <span>
              {params.row.score} / {params.row.descendants}
            </span>
          ) : null,
      },
      {
        field: "sentiment",
        headerName: "Sentiment",
        width: sentimentWidth,
        renderCell: (params) => {
          if (params && params.row) {
            const { sentiment } = params.row;
            const bgColor = sentimentColorRange(lowest, highest)(sentiment);
            return (
              params &&
              params.row && (
                <div
                  className={classes.sentimentCell}
                  style={{
                    backgroundColor:
                      sentiment > -2 && sentiment < 2
                        ? bgColor.replace(")", ", 0").replace("rgb", "rgba")
                        : bgColor,
                  }}
                >
                  {params.row.sentiment}
                </div>
              )
            );
          }
        },
      },
      {
        field: "subjectivity",
        width: sentimentWidth,
        headerName: "Subjectivity",
        width: subjectivityWidth,
        renderCell: (params) =>
          params && params.row && params.row.subjectivity
            ? `${params.row.subjectivity}%`
            : null,
      },
      {
        field: "time",
        headerName: "Age",
        width: 50,
        renderCell: (params) =>
          params &&
          params.row &&
          params.row.time && (
            <div className="cell--age">
              {moment.unix(params.row.time).fromNow(true)}
            </div>
          ),
      },
    ];
  }

  render() {
    const { columns } = this.state;
    const {
      lowestSentimentArticles,
      highestSentimentArticles,
      filteredHeadlines,
      highestScoreArticles,
      classes,
    } = this.props;

    if (columns && filteredHeadlines) {
      return (
        <>
          <div className="row">
            <div className="col">
              <DataGrid
                className={classes.root}
                rows={lowestSentimentArticles}
                columns={this.getColumns(true)}
                rowHeight={rowSize}
                hideFooter={true}
                headerHeight={rowSize}
                autoHeight={true}
                pageSize={10}
                wrap="wrap"
              />
            </div>
            <div className="col">
              <DataGrid
                className={classes.root}
                rows={highestSentimentArticles}
                columns={this.getColumns(true)}
                rowHeight={rowSize}
                hideFooter={true}
                headerHeight={rowSize}
                autoHeight={true}
                pageSize={10}
              />
            </div>
          </div>
          <div className="row">
            <DataGrid
              className={classes.root}
              rows={highestScoreArticles}
              columns={this.getColumns()}
              rowHeight={rowSize}
              headerHeight={rowSize}
              autoHeight={true}
              pageSize={10}
              wrap="wrap"
            />
          </div>
        </>
      );
    } else {
      return null;
    }
  }
}

const structuredSelector = createStructuredSelector({
  filteredHeadlines: (state) => state.headlines.filteredHeadlines,
  lowestSentimentArticles: (state) => state.headlines.lowestSentimentArticles,
  highestSentimentArticles: (state) => state.headlines.highestSentimentArticles,
  highestScoreArticles: (state) => state.headlines.highestScoreArticles,
});

const mapDispatchToProps = {};
export default connect(
  structuredSelector,
  mapDispatchToProps
)(withStyles(styles)(BasicGrid));
