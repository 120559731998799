import React, { PureComponent } from "react";
import * as d3 from "d3";
import moment from "moment";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import groupBy from "lodash/groupBy";

import { changeDates } from "../../actions/headlines";
import { themeColors } from "../../constants/colors";

import "./TimeSeriesLineChart.scss";

let brushGroup;
let brush;

function buildChart(props, isFirst) {
  const { changeDates, selectedHeadlines, dateFilter } = props;

  if (typeof selectedHeadlines === "undefined") {
    return;
  }

  const height = 60;
  const width = 600;
  const margin = { top: 15, right: 30, bottom: 20, left: 30 };
  const data = selectedHeadlines.map((item) => {
    item.value = item.sentiment;
    item.date = moment.unix(item.time).format("MM/DD/YYYY-HH");

    return item;
  });
  const result = groupBy(data, "date");
  const mapped = Object.keys(result).map((item, i) => {
    return {
      date: moment(item, "MM/DD/YYYY-HH").utc(),
      value: result[item].length,
    };
  });

  const max = d3.max(mapped, (d) => Math.abs(d.value));

  const dateExtent = d3.extent(mapped, (d) => d.date);
  const x = d3
    .scaleUtc()
    .domain([dateExtent[0], moment.utc(dateExtent[1]).add(4, "hour").utc()])
    .range([margin.left, width - margin.right]);

  const y = d3
    .scaleLinear()
    .domain([0, max * 1.2])
    .range([0, height - margin.top]);

  const colorRange = ["#0fe80f", "#d13030"];
  const z = d3
    .scaleLinear()
    .domain([-max, max])
    .interpolate(d3.interpolateHcl)
    .range([d3.hcl(colorRange[1]), d3.hcl(colorRange[0])]);

  brush = d3
    .brushX()
    .extent([
      [margin.left - 5, 5],
      [width - margin.right, height - margin.bottom + 5],
    ])
    .on("end", updateChart);

  function isBrushed(brush_coords, cx, cy) {
    var x0 = brush_coords[0][0],
      x1 = brush_coords[1][0],
      y0 = brush_coords[0][1],
      y1 = brush_coords[1][1];
    return x0 <= cx && cx <= x1 && y0 <= cy && cy <= y1; // This return TRUE or FALSE depending on if the points is in the selected area
  }

  const xAxis = (g) =>
    g
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(x).ticks())
      .call((g) => g.select(".domain").remove())
      .selectAll(".tick")
      .attr("color", themeColors.textMeta)
      .attr("stroke-width", "1px")
      .attr("font-size", "9px")
      .select("line")
      .remove();

  const yAxis = (g) => g;

  if (!isFirst) {
    d3.select("#timeBrush").attr("id", "brush-chart").html(" ");
  }

  const svg = d3
    .select("#brush-chart")
    .attr("id", "timeBrush")
    .attr("viewBox", [0, 0, width, height]);

  var line = d3
    .line()
    .x(function (d) {
      return x(d.month);
    })
    .y(function (d) {
      return y(d.count);
    });

  svg
    .append("path")
    .datum(mapped)
    .attr("class", "line")
    .attr("class", "line-asd")
    .attr("d", line);

  svg.append("g").call(xAxis);

  svg.append("g").call(yAxis);

  const bars = svg
    .append("g")
    .attr("stroke", "#000")
    .attr("stroke-opacity", 1)
    .attr("stroke-width", 0.1)
    .selectAll("rect")
    .data(mapped)
    .join("rect")
    .attr("class", "bar")
    .attr("x", (d) => x(d.date) - 0.5)
    .attr("y", (d) => height - margin.top - y(d.value))
    .attr("height", (d) => y(d.value))
    .attr("width", 1)
    .attr("fill", themeColors.backgroundButton)
    .attr("opacity", 1);

  brushGroup = svg.append("g").attr("class", "brush").call(brush);

  function updateChart(evt) {
    const extent = evt.selection;
    if (extent != null) {
      const newDates = [
        x.invert(extent[0]).getTime() / 1000,
        x.invert(extent[1]).getTime() / 1000,
      ];

      changeDates(newDates);
      bars._groups[0].forEach((item) => {
        const bar = d3.select(item);
        const barCenter = parseFloat(bar.attr("x")) + bar.attr("width") / 2;

        isBrushed = extent[0] <= barCenter && extent[1] >= barCenter;

        if (isBrushed) {
          bar
            .transition()
            .duration(200)
            .style("fill", themeColors.backgroundButtonActive);
        } else {
          bar
            .transition()
            .duration(200)
            .style("fill", themeColors.backgroundButton);
        }
      });
    }
  }

  const defaultSelection = [x.range()[0], x.range()[1]];
  brushGroup.call(brush.move, defaultSelection);
}

class TimeSeriesColumnChart extends PureComponent {
  componentDidMount() {
    buildChart(this.props, true);
  }

  componentDidUpdate() {
    buildChart(this.props, false);
  }

  render() {
    return (
      <div className="time-selector-container">
        <svg id="brush-chart" />
      </div>
    );
  }
}

const structuredSelector = createStructuredSelector({
  selectedHeadlines: (state) => state.headlines.selectedHeadlines,
});

const mapDispatchToProps = { changeDates };
export default connect(
  structuredSelector,
  mapDispatchToProps
)(TimeSeriesColumnChart);
