import { Headlines, Sources } from "../constants/constants";

export const fetchHeadlinesSuccess = (data) => {
  return {
    type: Headlines.fetchHeadlinesSuccess,
    payload: data,
  };
};

export const fetchHeadlines = (data) => {
  return {
    type: Headlines.fetchHeadlines,
    payload: data,
  };
};

export const fetchHeadlinesError = (data) => {
  return {
    type: Headlines.fetchHeadlinesError,
    payload: data,
  };
};

export const selectHeadlines = (data) => {
  return {
    type: Headlines.selectHeadlines,
    payload: data,
  };
};

export const updateFilters = (data) => {
  return {
    type: Headlines.updateFilters,
    payload: data,
  };
};

export const updateFilteredHeadlines = (data) => {
  return {
    type: Headlines.updateFilteredHeadlines,
    payload: data,
  };
}

export const updateSelectedHeadlinesDateRange = (data) => {
  return {
    type: Headlines.updateSelectedHeadlinesDateRange,
    payload: data,
  };
};

export const createChips = (data) => {
  return {
    type: Headlines.createChips,
    payload: data,
  };
};

export const toggleChip = (data) => {
  return {
    type: Headlines.toggleChip,
    payload: data,
  };
};

export const changeDates = (data) => {
  return {
    type: Headlines.changeDates,
    payload: data,
  };
};

export const fetchSourcesSuccess = (data) => {
  return {
    type: Sources.fetchSourcesSuccess,
    payload: data,
  };
};

export const fetchSources = (data) => {
  return {
    type: Sources.fetchSources,
    payload: data,
  };
};

export const fetchSourcesError = (data) => {
  return {
    type: Sources.fetchSourcesError,
    payload: data,
  };
};

export const selectSources = (data) => {
  return {
    type: Sources.selectSources,
    payload: data,
  };
};
