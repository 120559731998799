import orderBy from "lodash/orderBy";
import countBy from "lodash/countBy";

import { Headlines, Sources } from "../constants/constants";
import { tagger } from "../services/natural";

const initialState = {
  headlines: [],
  lowestSentimentArticles: [],
  highestSentimentArticles: [],
  highestScoreArticles: [],
  dateFilter: [],
  selectedHeadlines: [],
  filteredHeadlines: [],
  selectedHeadlinesDateRange: [],
  chipSelections: [],
  chipSelectionData: {}
};

const headlinesReducer = (state = initialState, action) => {
  switch (action.type) {
    case Sources.fetchSources:
      return state;
    case Sources.fetchSourcesSuccess:
      return {
        ...state,
        sources: action.payload,
      };
    case Sources.selectSources:
      const newSources = action.payload && action.payload.length === 0 ? [] : action.payload;
      return {
        ...state,
        selectedSources:newSources
      };
    case Headlines.fetchHeadlines:
      return state;
    case Headlines.fetchHeadlinesSuccess:
      const newHeadlines = state.headlines ? state.headlines.concat(action.payload) : action.payload;
      return {
        ...state,
        headlines: newHeadlines,
      };
    case Headlines.updateSelectedHeadlinesDateRange:
      return {
        ...state,
        selectedHeadlinesDateRange: action.payload,
      };
    case Headlines.updateFilters:
      return {
        ...state,
        ...action.payload
      };
    case Headlines.updateFilteredHeadlines:
      return {
        ...state,
        filteredHeadlines: action.payload
      };
    case Headlines.selectHeadlines:
      return {
        ...state,
        selectedHeadlines: action.payload,
        dateFilter: null
      };
    case Headlines.createChips:
      const headlinesForChips = state.selectedHeadlines;

      function getWordOccurences() {
        if (headlinesForChips && headlinesForChips.length && headlinesForChips.length > 0) {
          const text = headlinesForChips
            .map((item) => item.title)
            .join(" ")
            .split(" ");

          const frequencies = countBy(text);
          const arrayOfObj = Object.entries(frequencies).map((e) => ({
            text: tagger.tag([e[0]]),
            count: e[1],
          }));
          const ordered = orderBy(arrayOfObj, "count").reverse();

          return ordered;
        }
      }

      const ordered = getWordOccurences();

      if (ordered && ordered.length > 0) {
        const filtered = ordered.filter(
          (item) =>
            item.text.taggedWords[0].tag &&
            item.text.taggedWords[0].tag.indexOf("NNP") > -1 &&
            item.text.taggedWords[0].token.length > 2
        ).map(item => ({
          count: item.count,
          text: item.text.taggedWords[0].token
        }));
  
        return {
          ...state,
          chips: filtered,
        };
      } 
    case Headlines.changeDates:
      return {
        ...state,
        dateFilter: action.payload,
      };
    case Headlines.toggleChip:
      const chip = action.payload;
      const chipData = state.chipSelectionData || {};
      let newChips = state.chipSelections ? [].concat(state.chipSelections) : [];

      if (Array.isArray(chip)) {
        newChips = chip;
        // chipData[chip] = getAllChipHeadlines(chip, state.filteredHeadlines)
      } else {
        if (newChips.indexOf(chip) > -1) {
          newChips = newChips.filter((item) => item !== chip);
        } else {
          newChips.push(chip);
          // chipData[chip] = getAllChipHeadlines(chip, state.filteredHeadlines)
        }
      }

      return {
        ...state,
        chipSelections: newChips,
        // chipSelectionData: chipData
      };
    default:
      return state;
  }
};

function getAllChipHeadlines(chip, headlines) {
  return headlines.filter(
    (article) =>
      article.title.indexOf(chip) > -1
  );
}

export default headlinesReducer;
