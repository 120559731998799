
import { Global } from "../constants/constants";

const initialState = {
  fetchingData: false
};

const headlinesReducer = (state = initialState, action) => {
  switch (action.type) {
    case Global.fetchingData:
      return {
        ...state,
        fetchingData: true,
      }
    case Global.fetchingDataSuccess:
      return {
        ...state,
        fetchingData: false,
      }
    case Global.fetchingDataFailed:
      return {
        ...state,
        fetchingData: false,
      }
    default:
      return state;
  }
};

export default headlinesReducer;
