import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import Paper from "@material-ui/core/Paper";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import ClearIcon from "@material-ui/icons/Clear";

import { selectSources } from "../../actions/headlines";
import { themeColors } from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 450,
    minWidth: 450,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputLabel-filled": {
      color: themeColors.textBody,
    },
    "& .MuiFormControl-fullWidth": {
      backgroundColor: themeColors.backgroundInput,
    },
    "& .MuiIconButton-root": {
      padding: "0 5px",
      background: themeColors.backgroundInput,
    },
    "& .MuiSvgIcon-root": {
      fill: themeColors.iconFill,
    },
    "& .MuiInputBase-root .MuiInputBase-input.MuiAutocomplete-input": {
      height: "auto",
      color: themeColors.textBody,
      padding: "4px 5px",
      fontSize: "12px",
      "&::placeholder": {
        color: themeColors.textBody,
        textAlign: "right",
      },
    },
    "& .MuiChip-root": {
      margin: "0px",
      height: "26px",
      margin: "2px 0 0 2px",
      background: "#23282f",
      height: "24px",
    },
    "& .MuiChip-deleteIcon": {
      fill: themeColors.backgroundButton,
      height: "16px",
    },
    "& .MuiAutocomplete-endAdornment": {
      padding: "3px 4px 0 0",
      "& .MuiSvgIcon-root": {
        fill: themeColors.backgroundButton,
      },
    },
    "& .MuiAutocomplete-inputRoot": {
      overflow: "hidden",
    },
    "& .MuiAutocomplete-popupIndicator": {
      marginRight: "-8px",
      padding: "2px 2px",
      borderRadius: "0px",
    },
    "& .MuiAutocomplete-tag": {
      color: themeColors.textInput,
      fontSize: "12px",
    },
  },
  icon: {
    margin: "2px 5px 0 5px",
    display: "flex",
    height: "100%",
    "& svg": {
      height: "15px",
    },
    "& svg path": {
      fill: themeColors.backgroundInput,
    },
  },
  paper: {
    backgroundColor: themeColors.backgroundButton,
    minWidth: 350,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiAutocomplete-inputRoot": {
      overflow: "hidden",
      display: "flex",
      flexWrap: "nowrap",
    },
  },
  button: {
    margin: "0 0 0 10px",
  },
}));

function SourceSelector(props) {
  const { sources, onChange, selectedSources, selectSources } = props;
  const [cachedValue, setValue] = useState(selectedSources);
  const [hasChanged, setHasChanged] = useState(0);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {selectedSources && (
        <Autocomplete
          options={sources}
          limitTags={2}
          disableCloseOnSelect
          multiple
          disableClearable={true}
          defaultValue={selectedSources}
          value={
            cachedValue && cachedValue.length > selectedSources.length
              ? cachedValue
              : selectedSources
          }
          noOptionsText="Please select source(s)"
          renderTags={(value, getTagProps) => {
            const valueToUse =
              cachedValue && Array.isArray(cachedValue) ? cachedValue : value;
            return valueToUse.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ));
          }}
          onChange={(event, newInputValue) => {
            if (Array.isArray(newInputValue)) {
              setValue(newInputValue);
              if (newInputValue.length < selectedSources.length) {
                selectSources(newInputValue);
              }
            } else if (
              typeof newInputValue === "string" ||
              newInputValue instanceof String
            ) {
              setValue([]);
              selectSources([]);
            }
            setHasChanged(true);
          }}
          onClose={(event, newInputValue) => {
            if (cachedValue && cachedValue.length > 0 && hasChanged) {
              selectSources(cachedValue);
              setHasChanged(false);
            }
          }}
          renderInput={(params) => (
            <Paper className={classes.paper}>
              <div className={classes.icon}>
                <MenuOpenIcon />
              </div>
              <TextField {...params} />
            </Paper>
          )}
        />
      )}

      <Button
        variant="contained"
        size="small"
        color="primary"
        className={classes.button}
        onClick={() => {
          const newSources = sources;
          setValue(newSources);
          selectSources(newSources);
          setHasChanged(true);
        }}
      >
        <AllInclusiveIcon />
      </Button>
      <Button
        variant="contained"
        size="small"
        color="primary"
        className={classes.button}
        onClick={() => {
          const newSources = [];
          setValue(newSources);
          selectSources(newSources);
          setHasChanged(true);
        }}
      >
        <ClearIcon />
      </Button>
    </div>
  );
}

const structuredSelector = createStructuredSelector({
  selectedSources: (state) => state.headlines.selectedSources,
  sources: (state) => state.headlines.sources,
});

const mapDispatchToProps = { selectSources };
export default connect(structuredSelector, mapDispatchToProps)(SourceSelector);
