import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import * as d3 from "d3";

import { themeColors } from "../../constants/colors";

function ForceDirectedGraph(props) {
  const { filteredHeadlines, chips } = props;

  function buildChart() {
    if (chips && chips.length > 0) {
      const data = chips
        .map((item) => ({
          name: item.text,
          value: item.count,
        }))
        .splice(0, 50);

      const width = 600;
      const height = 300;
      const format = d3.format(",d");
      const color = d3.scaleOrdinal(
        data.map((d) => d.group),
        [themeColors.backgroundButton]
      );
      const pack = (data) =>
        d3
          .pack()
          .size([width - 2, height - 2])
          .padding(3)(d3.hierarchy({ children: data }).sum((d) => d.value));

      const root = pack(data);

      const svg = d3
        .create("svg")
        .attr("viewBox", [0, 0, width, height])
        .attr("fontSize", 3)
        .attr("xmlns", "http://www.w3.org/2000/svg")
        .attr("xmlnsXlink", "http://www.w3.org/1999/xlink")
        .attr("fontFamily", "'Roboto Condensed', sans-serif")
        .attr("text-anchor", "middle");

      const leaf = svg
        .selectAll("g")
        .data(root.leaves())
        .join("g")
        .attr("transform", (d) => `translate(${d.x + 1},${d.y + 1})`);

      leaf
        .append("circle")
        .attr("id", (d) => (d.leafUid = `${d.data.name}-circ`))
        .attr("r", (d) => d.r)
        .attr("fillOpacity", 0.7)
        .attr("fill", (d) => {
          return color(d.data.group);
        });

      leaf
        .append("clipPath")
        .attr("id", (d) => (d.clipUid = `${d.data.name}-clipPath`))
        .append("use")
        .attr("xlinkHref", (d) => d.leafUid.href);

      leaf
        .append("text")
        .attr(
          "style",
          (d) =>
            `font-size: ${(d.r * 3) / d.data.name.length}px; fill: ${
              themeColors.textBody
            }; pointer-events: none`
        )
        .attr("clipPath", (d) => d.clipUid)
        .selectAll("tspan")
        .data(data)
        .data((d) => {
          return d.data.name.split(/(?=[A-Z][a-z])|\s+/g);
        })
        .join("tspan")
        .attr("x", 0)
        .attr("y", (d, i, nodes) => `${i - nodes.length / 2 + 0.8}em`)
        .text((d) => d);

      leaf.append("title").text(
        (d) =>
          `${
            d.data.title === undefined
              ? ""
              : `${d.data.title}
        `
          }${format(d.value)}`
      );

      const s = new XMLSerializer();
      return svg.node() ? s.serializeToString(svg.node()) : null;
    }
  }

  if (filteredHeadlines) {
    const chart = buildChart();
    return (
      <>{chart && <div dangerouslySetInnerHTML={{ __html: buildChart() }} />}</>
    );
  } else {
    return null;
  }
}

const structuredSelector = createStructuredSelector({
  filteredHeadlines: (state) => state.headlines.filteredHeadlines,
  chips: (state) => state.headlines.chips,
});

const mapDispatchToProps = {};
export default connect(
  structuredSelector,
  mapDispatchToProps
)(ForceDirectedGraph);
