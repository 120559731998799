import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/Done";

import { themeColors } from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    margin: "0 10px 5px 0",
    padding: "0 0 0 10px",
    borderRadius: "4px",
    background: themeColors.backgroundButton,
    display: "flex",
    alignSelf: "center",
    lineHeight: "26px",
    justifyContent: "space-between",
    color: themeColors.textBody,
    "&:hover": {
      background: themeColors.backgroundButtonHover,
      color: themeColors.textLinkHover,
    },
    "&:active": {
      background: themeColors.backgroundButtonHover,
    },
    "&:focus": {
      background: themeColors.backgroundButton,
    },
  },
  icon: {
    color: themeColors.iconFill,
  },
  selected: {
    background: themeColors.backgroundButtonActive,
    color: themeColors.textLinkHover,
    "&:hover": {
      background: themeColors.backgroundButtonActive,
    },
    "&:active": {
      background: themeColors.backgroundButtonActive,
    },
    "&:focus": {
      background: themeColors.backgroundButtonActive,
    },
  },
  count: {
    margin: "0 0 0 5px",
    background: "#2e3740",
    borderRadius: "0 4px 4px 0",
    padding: "0 4px",
    fontSize: "12px",
    lineHeight: "26px",
    color: themeColors.textMeta,
    borderRight: `1px solid ${themeColors.backgroundButton}`,
  },
  countSelected: {
    fontWeight: 800,
    borderRight: `1px solid ${themeColors.backgroundButtonHover}`,
  },
}));

export default function Chip(props) {
  const { label, onClick, selected, count, chipColor } = props;
  const classes = useStyles();

  return (
    <div
      className={
        selected ? `${classes.root} ${classes.selected}` : classes.root
      }
      style={{ background: chipColor }}
      onClick={() => onClick(label)}
    >
      <div className={classes.label}>{label}</div>
      <div
        className={
          selected ? `${classes.count} ${classes.countSelected}` : classes.count
        }
      >
        {count}
      </div>
    </div>
  );
}
