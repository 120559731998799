import React from "react";
import * as d3 from "d3";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import { sentimentColorRange, themeColors } from "../../constants/colors";

import countBy from "lodash/countBy";
import orderBy from "lodash/orderBy";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    color: themeColors.textInput,
    paddingLeft: "10px",
    fontSize: "14px",
  },
}));

function getAllChipHeadlines(chip, headlines) {
  return headlines.filter((article) => article.title.indexOf(chip) > -1);
}

function Sparkline(props) {
  const { headlines, chipSelection, chipSelectionData } = props;
  const classes = useStyles();

  function buildChart() {
    const WIDTH = 200;
    const HEIGHT = 100;
    const MARGIN = { top: 5, right: 0, bottom: 5, left: 5 };
    const INNER_WIDTH = WIDTH - MARGIN.left - MARGIN.right;
    const INNER_HEIGHT = HEIGHT - MARGIN.top - MARGIN.bottom;

    const chipData = [];
    getAllChipHeadlines(chipSelection, headlines)
      .sort((a, b) => a.time - b.time)
      .forEach((item, i) => {
        chipData.push({
          time: moment.unix(item.time).utc(),
          cumulative: i,
        });
      });

    if (!chipData || chipData.length === 0) return;

    const x = d3
      .scaleUtc()
      .domain(d3.extent(chipData, (d) => d.time))
      .range([MARGIN.left, INNER_WIDTH]);
    const y = d3
      .scaleLinear()
      .domain([0, d3.max(chipData, (d) => d.cumulative)])
      .range([INNER_HEIGHT, MARGIN.top]);

    const svg = d3
      .create("svg")
      .attr("viewBox", [0, 0, WIDTH, HEIGHT])
      .attr("width", WIDTH)
      .attr("height", HEIGHT);
    const line = d3
      .line()
      .curve(d3.curveLinear)
      .x((d) => x(d.time))
      .y((d) => y(d.cumulative));

    var lg = svg
      .append("defs")
      .append("linearGradient")
      .attr("id", "mygrad") //id of the gradient
      .attr("x1", "0%")
      .attr("x2", "0%")
      .attr("y1", "0%")
      .attr("y2", "100%"); //since its a vertical linear gradient
    lg.append("stop")
      .attr("offset", "0%")
      .style("stop-color", "rgba(94, 127, 168, 1)")
      .style("stop-opacity", 0.2);

    lg.append("stop")
      .attr("offset", "100%")
      .style("stop-color", "rgba(94, 127, 168, 1)")
      .style("stop-opacity", 0);

    // Add the area
    svg
      .append("path")
      .datum(chipData)
      // .attr("fill", "rgba(94, 127, 168, .1)")
      .style("fill", "url(#mygrad)") //id of the gradient for fill
      .attr("stroke-width", 1.5)
      .attr(
        "d",
        d3
          .area()
          .x(function (d) {
            return x(d.time);
          })
          .y0(y(0))
          .y1(function (d) {
            return y(d.cumulative);
          })
      );

    // Add line
    svg
      .append("path")
      .datum(chipData)
      .attr("fill", "none")
      .attr("stroke", themeColors.backgroundButtonHover)
      .attr("stroke-width", 1)
      .attr("d", line);

    svg
      .append("circle")
      .attr("r", 2)
      .attr("cx", x(chipData[chipData.length - 1].time))
      .attr("cy", y(chipData[chipData.length - 1].cumulative))
      .attr("fill", themeColors.backgroundButtonActive);

    const s = new XMLSerializer();
    return svg.node() ? s.serializeToString(svg.node()) : null;
  }

  if (headlines) {
    const chart = buildChart();

    return (
      <>
        {chart && (
          <div
            className={classes.root}
            dangerouslySetInnerHTML={{ __html: buildChart() }}
          />
        )}
      </>
    );
  } else {
    return null;
  }
}

const structuredSelector = createStructuredSelector({
  headlines: (state) => state.headlines.selectedHeadlines,
  chipSelectionData: (state) => state.headlines.chipSelectionData,
});

const mapDispatchToProps = {};
export default connect(structuredSelector, mapDispatchToProps)(Sparkline);
