import React, { PureComponent } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import TimeSeriesLineChart from "../TimeSeriesLineChart";
import { changeDates } from "../../actions/headlines";

import "./styles.scss";

class Footer extends PureComponent {
  now() {
    const { selectedHeadlinesDateRange } = this.props;
    return moment.unix(selectedHeadlinesDateRange[1]);
  }

  render() {
    const {
      filteredHeadlines,
      changeDates,
      selectedHeadlinesDateRange,
      children,
    } = this.props;

    return (
      <div className="footer-container">
        {filteredHeadlines && filteredHeadlines.length > 0 && children}
        {/* <div className="quick-date-filters">
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              changeDates([this.now().utc().startOf("day").unix(), selectedHeadlinesDateRange[1]]);
            }}
          >
            Today
          </Button>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              changeDates([this.now().utc().subtract(1, "day").unix(), selectedHeadlinesDateRange[1]]);
            }}
          >
            Last 24 hr
          </Button>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              changeDates([this.now().utc().subtract(7, "day").unix(), selectedHeadlinesDateRange[1]]);
            }}
          >
            Last 7 days
          </Button>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              changeDates([this.now().utc().subtract(1, "month").unix(), selectedHeadlinesDateRange[1]]);
            }}
          >
            This month
          </Button>
        </div> */}
      </div>
    );
  }
}

const structuredSelector = createStructuredSelector({
  filteredHeadlines: (state) => state.headlines.filteredHeadlines,
  selectedHeadlinesDateRange: (state) =>
    state.headlines.selectedHeadlinesDateRange,
});

const mapDispatchToProps = { changeDates };
export default connect(structuredSelector, mapDispatchToProps)(Footer);
