const storage = window.localStorage;

export function read(key) {
  return storage.getItem(key)
}

export function write(key, data) {
  return storage.setItem(key, data)
}

export function readJSON(key) {
  return JSON.parse(storage.getItem(key))
}

export function writeJSON(key, data) {
  return storage.setItem(key, JSON.stringify(data))
}