import React from "react";
import * as d3 from "d3";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { makeStyles } from "@material-ui/core/styles";

import { sentimentColorRange, themeColors } from "../../constants/colors";

import countBy from "lodash/countBy";
import orderBy from "lodash/orderBy";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "20%",
  },
}));

function BarChart(props) {
  const { filteredHeadlines } = props;
  const classes = useStyles();

  function buildChart() {
    const height = 250;
    const width = 150;
    const margin = { top: 15, right: 10, bottom: 20, left: 10 };

    const countData = countBy(filteredHeadlines, "sentiment");
    const dataGrouped = Object.keys(countData).map((key) => ({
      id: parseInt(key),
      value: countData[key],
    }));
    const count = filteredHeadlines.length;
    const dataArray = orderBy(dataGrouped, ["id"], ["desc"]);

    const max = d3.max(dataArray, (d) => Math.abs(d.id));
    const adjustedMax = Math.round(max * 1.2);
    let yRange = [];

    for (var i = adjustedMax; i >= -adjustedMax; i--) {
      yRange.push(i);
    }

    var x = d3.scaleLinear().range([width - margin.right - margin.left, 0]);
    var y = d3
      .scaleOrdinal()
      .range(
        yRange.map(
          (item, i) =>
            ((height - margin.bottom) / yRange.length) * i + margin.top
        )
      );

    const xValueLimit =
      d3.max(dataArray, function (d) {
        return d.value;
      }) * 1.5;

    x.domain([xValueLimit, 0]);
    y.domain(yRange);

    const z = sentimentColorRange(-adjustedMax, adjustedMax);

    const yAxis = (g) =>
      g.call((g) =>
        g
          .append("text")
          .attr("fill", "#fff")
          .attr("x", 5)
          .attr("y", margin.top)
          .attr("dy", "0.32em")
          .attr("text-anchor", "start")
          .attr("font-family", "Roboto Condensed")
          .attr("font-size", "9px")
          .text("Total")
      );

    // .attr("transform", `translate(${margin.left},0)`)
    // .call(d3.axisLeft(y).ticks())
    // .call(g => g.select(".domain").remove())
    // .selectAll('.tick')
    // .attr('color', themeColors.textMeta)
    // .attr('stroke-width', '1px')
    // .attr('font-size', '9px')

    const xAxis = (g) =>
      g
        .attr(
          "transform",
          `translate(${margin.left},${height - margin.bottom})`
        )
        .call(
          d3
            .axisBottom(x)
            .ticks(4)
            .tickFormat(d3.formatPrefix(".1", xValueLimit))
        )
        .call((g) => g.select(".domain").remove())
        .selectAll(".tick")
        .attr("color", themeColors.textMeta)
        .attr("font-size", "9px")
        .attr("stroke-width", "1px")
        .attr("stroke-opacity", 1);

    const svg = d3.create("svg").attr("viewBox", [0, 0, width, height]);

    svg.append("g").call(xAxis);

    svg.append("g").call(yAxis);

    svg
      .append("g")
      .attr("stroke", "#000")
      .attr("stroke-opacity", 0)
      .attr("stroke-width", 0.1)
      .selectAll("rect")
      .data(dataArray)
      .join("rect")
      .attr("width", (d) => x(d.value))
      .attr("height", "4")
      .attr("y", (d) => y(d.id) - 5)
      .attr("x", margin.left)
      .attr("fill", (d) => z(d.id))
      .attr("rx", "2px")
      .attr("ry", "2px");

    svg
      .append("g")
      .selectAll("text")
      .data(dataArray)
      .join("text")
      .html((d) => {
        const percentage = Math.round((d.value / count) * 100);
        if (percentage > 0) {
          return `${percentage}%`;
        }
      })
      .attr("font-size", 8)
      .attr("fill", themeColors.textMeta)
      .attr("height", "4")
      .attr("y", (d) => y(d.id))
      .attr("x", (d) => x(d.value) + margin.left + 5);

    const s = new XMLSerializer();
    return svg.node() ? s.serializeToString(svg.node()) : null;
  }

  if (filteredHeadlines) {
    const chart = buildChart();

    return (
      <>
        {chart && (
          <div
            className={classes.root}
            dangerouslySetInnerHTML={{ __html: buildChart() }}
          />
        )}
      </>
    );
  } else {
    return null;
  }
}

const structuredSelector = createStructuredSelector({
  filteredHeadlines: (state) => state.headlines.filteredHeadlines,
});

const mapDispatchToProps = {};
export default connect(structuredSelector, mapDispatchToProps)(BarChart);
