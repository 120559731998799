import { combineReducers } from "redux";

import headlinesReducer from "./headlines";
import globalReducer from "./global";

// right now we have only 1 reducer, but lets use this format of combineReducers so you can add more later if you need to.
const rootReducer = combineReducers({
  global: globalReducer,
  headlines: headlinesReducer,
});

export default rootReducer;
