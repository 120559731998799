export const Headlines = {
  fetchHeadlines: "FETCH_HEADLINES",
  fetchHeadlinesSuccess: "FETCH_HEADLINES_SUCCESS",
  fetchHeadlinesError: "FETCH_HEADLINES_ERROR",
  createChips: "CREATE_CHIPS",
  selectHeadlines: "SELECT_HEADLINES",
  updateFilters: "UPDATE_FILTERS",
  toggleChip: "TOGGLE_CHIP",
  changeDates: "CHANGE_DATE",
  updateSelectedHeadlinesDateRange: "UPDATE_SELECTED_HEADLINES_DATE_RANGE",
  updateFilteredHeadlines: "UDATE_FILTERED_HEADLINES"
};

export const Sources = {
  fetchSources: "FETCH_SOURCES",
  fetchSourcesSuccess: "FETCH_SOURCES_SUCCESS",
  fetchSourcesError: "FETCH_SOURCES_ERROR",
  selectSources: "SELECT_SOURCES",
};

export const Global = {
  fetchingData: "FETCHING_DATA",
  fetchingDataSuccess: "FETCHING_DATA_SUCCESS",
  fetchingDataFailed: "FETCHING_DATA_FAILED",
};
