import React, { PureComponent, Suspense } from "react";
import {
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import SearchBox from "./components/SearchBox/SearchBox";
import BasicGrid from "./components/BasicGrid/BasicGrid";
import WordCloud from "./components/WordCloud/WordCloud";
import SourceSelector from "./components/SourceSelector";
import DotTrend from "./components/DotTrend/DotTrend";
import BarChart from "./components/BarChart/BarChart";
import BubbleChart from "./components/BubbleChart/BubbleChart";
import LoaderPopUp from "./components/Loader/LoaderPopUp";
import Footer from "./components/Footer";
import SentenceList from './components/SentenceList';
import TimeSeriesLineChart from './components/TimeSeriesLineChart';
import TrendLine from './components/TrendLine';

import { init } from './services/firestore'

import { fetchSources } from "./actions/headlines";

import {themeColors} from './constants/colors'

import "./styles/App.scss";
import { interpolateNumber } from "d3-interpolate";
import { intersection } from "lodash";

const theme = createMuiTheme({
  overrides: {
    MuiDataGrid: {
      root: {
        color: themeColors.textBody,
        '& .MuiDataGrid-colCellTitle': {
          color: themeColors.textMeta,
          TextTransform: 'uppercase',

          '&:hover': {
            color: themeColors.textLinkHover,
          }
        },
        '& .MuiDataGrid-iconSeparator': {
          fill: themeColors.textMeta
        }
      },
    },
    MuiSvgIcon: {
      root: {
        height: "20px",
      },
    },
    MuiIconButton: {
      root: {
        color: themeColors.textBody,
        fill: themeColors.textBody,
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: themeColors.backgroundButton,
        color: themeColors.textBody,
        minWidth: "32px",
        "&:hover": {
          backgroundColor: themeColors.backgroundButtonHover,
        },
      },
      containedSizeSmall: {
        fontFamily: "Roboto Condensed, sans-serif",
        fontSize: "12px",
        padding: "3px 8px",
      },
    },
    MuiAutocomplete: {
      root: {
        minWidth: '200px'
      },
    },
    MuiTypography: {
      body2: {
        fontSize: '12px'
      }
    }
  },
});

class App extends PureComponent {
  componentWillMount() {
    const _this = this;
    init().then(() => {
      _this.props.fetchSources();
    })
  }

  render() {
    const {
      filteredHeadlines = [],
      fetchingData,
      selectedSources = [],
    } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <div className="App">
          <div className="row">
            <h1>newshacker</h1>
            <span className="article-count">[{filteredHeadlines.length}]</span>
          </div>
          <div className="row header-row">
            <div className="row">
              <SourceSelector />
            </div>
          </div>
          <Suspense>
            {selectedSources &&
                selectedSources.length === 0 && <div>PLEASE SELECT SOURCES</div>}
            {filteredHeadlines &&
              filteredHeadlines.length > 0 &&
              selectedSources &&
              selectedSources.length > 0 && (
                <>
                  <div className="row space-between">
                    <WordCloud />
                    <SearchBox />
                  </div>
                  <div className="row">
                  <TrendLine />
                  </div>
                  <BasicGrid />
                  <div className="row sentiment-charts">
                    <DotTrend />
                    <BarChart />
                  </div>
                  <BubbleChart />
                </>
              )}
            <Footer>
              <TimeSeriesLineChart />
            </Footer>
          </Suspense>
        </div>
        <LoaderPopUp />
      </ThemeProvider>
    );
  }
}

const structuredSelector = createStructuredSelector({
  filteredHeadlines: (state) => state.headlines.filteredHeadlines,
  selectedSources: (state) => state.headlines.selectedSources,
  fetchingData: (state) => state.global.fetchingData,
});

const mapDispatchToProps = { fetchSources };
export default connect(structuredSelector, mapDispatchToProps)(App);
