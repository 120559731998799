import { Global } from "../constants/constants";

export const fetchingData = (data) => {
  return {
    type: Global.fetchingData
  };
};

export const fetchingDataSuccess = (data) => {
  return {
    type: Global.fetchingDataSuccess
  };
};

export const fetchingDataFailed = (data) => {
  return {
    type: Global.fetchingDataFailed
  };
};

