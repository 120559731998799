import React from "react";
import Dialog from "@material-ui/core/Dialog";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Loader from "./index";

import "./styles.scss";

function LoaderPopUp(props) {
  return (
    <Dialog open={props.fetchingData} className="loader-dialog">
      <Loader />
    </Dialog>
  );
}

const structuredSelector = createStructuredSelector({
  fetchingData: (state) => state.global.fetchingData,
});

const mapDispatchToProps = {};
export default connect(structuredSelector, mapDispatchToProps)(LoaderPopUp);
