import React from "react";
import * as d3 from "d3";
import moment from "moment";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { sentimentColorRange, themeColors } from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
  },
}));

function DotTrend(props) {
  const { filteredHeadlines } = props;
  const classes = useStyles();

  function buildChart() {
    const height = 250;
    const width = 600;
    const margin = { top: 15, right: 20, bottom: 20, left: 30 };
    const data = filteredHeadlines.map((item) => {
      item.value = item.sentiment;
      item.date = moment.unix(item.time).utc();

      return item;
    });

    const max = d3.max(data, (d) => Math.abs(d.value));
    const adjustedMax = Math.round(max * 1.2);

    const x = d3
      .scaleUtc()
      .domain(d3.extent(data, (d) => d.date))
      .range([margin.left, width - margin.right]);

    const y = d3
      .scaleLinear()
      .domain([-adjustedMax, adjustedMax])
      .range([height - margin.bottom, margin.top]);

    const z = sentimentColorRange(-adjustedMax, adjustedMax);

    const xAxis = (g) =>
      g
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .call(d3.axisBottom(x).ticks(width / 80))
        .call((g) => g.select(".domain").remove())
        .selectAll(".tick")
        .attr("color", themeColors.textMeta)
        .attr("stroke-width", "1px")
        .attr("font-size", "9px");

    const yAxis = (g) =>
      g
        .attr("transform", `translate(${margin.left},0)`)
        .call(d3.axisLeft(y).ticks(null, "+"))
        .call((g) => g.select(".domain").remove())
        .call((g) =>
          g
            .selectAll(".tick line")
            .filter((d) => d === 0)
            .clone()
            .attr("x2", width - margin.right - margin.left)
            .attr("stroke", "#57616f")
        )
        .call((g) =>
          g
            .append("text")
            .attr("fill", "#fff")
            .attr("x", 5)
            .attr("y", margin.top)
            .attr("dy", "0.32em")
            .attr("text-anchor", "start")
            .attr("font-family", "Roboto Condensed")
            .text("Sentiment")
        )
        .selectAll(".tick")
        .attr("color", themeColors.textMeta)
        .attr("font-size", "9px")
        .attr("stroke-width", "1px")
        .attr("stroke-opacity", 1);

    const svg = d3.create("svg").attr("viewBox", [0, 0, width, height]);

    svg.append("g").call(xAxis);

    svg.append("g").call(yAxis);

    svg
      .append("g")
      .attr("stroke", "#000")
      .attr("stroke-opacity", 1)
      .attr("stroke-width", 0.1)
      .selectAll("circle")
      .data(data)
      .join("circle")
      .attr("cx", (d) => x(d.date))
      .attr("cy", (d) => y(d.value))
      .attr("fill", (d) => z(d.value))
      .attr("r", 1);

    const s = new XMLSerializer();
    return svg.node() ? s.serializeToString(svg.node()) : null;
  }

  if (filteredHeadlines) {
    const chart = buildChart();

    return (
      <>
        {chart && (
          <div
            className={classes.root}
            dangerouslySetInnerHTML={{ __html: buildChart() }}
          />
        )}
      </>
    );
  } else {
    return null;
  }
}

const structuredSelector = createStructuredSelector({
  filteredHeadlines: (state) => state.headlines.filteredHeadlines,
});

const mapDispatchToProps = {};
export default connect(structuredSelector, mapDispatchToProps)(DotTrend);
