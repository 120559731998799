import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { withStyles } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import Button from "@material-ui/core/Button";

import moment from "moment";

import Chip from "../Chip/Chip";
import SentenceList from "../SentenceList";
import Sparkline from "../Sparkline";
import Pie from "../Pie";
import { toggleChip } from "../../actions/headlines";
import { themeColors, getChipColors } from "../../constants/colors";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "100%",
  },
  chipWrapper: {
    "& .sentenceWrapper": {
      minWidth: "350px",
      minHeight: "50px",
      position: "absolute",
      backgroundColor: themeColors.backgroundBody,
      zIndex: 10,
      borderRadius: "4px",
      // display: "block",
      visibility: "hidden",
      opacity: 0,
      transition: "visibility .0s .0s, opacity .0s .0s ease-in",
    },
    "&:hover .sentenceWrapper": {
      // display: "block",
      boxShadow: "0px 10px 24px 4px rgba(0,0,0,0.6)",
      border: `1px solid ${themeColors.backgroundButton}`,
      padding: "10px 10px 10px",
      visibility: "visible",
      opacity: 1,
      transition: "visibility .15s .1s, opacity .15s .1s ease-in",
    },
  },
  button: {
    background: "none",
    color: themeColors.textMeta,
    padding: 0,
    border: 0,
    cursor: "pointer",
    display: "flex",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  icon: {},
  clearButton: {
    marginRight: "10px",
    padding: "2px 0",
    height: "26px",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    color: themeColors.textInput,
    margin: "0px 10px",
    fontSize: "14px",
  },
  pieWrapper: {
    width: "100px",
    "& p": {
      fontSize: "10px",
      margin: 0,
      color: themeColors.textBody,
    },
  },
});

class WordCloud extends PureComponent {
  state = {
    toggled: false,
    hoveredChip: "",
  };

  setToggled(value) {
    this.setState({
      toggled: value,
    });
  }

  setHovered(chipName) {
    this.setState({
      hoveredChip: chipName,
    });
  }

  unsetHovered() {
    this.setState({
      hoveredChip: "",
    });
  }

  limitByTime(startDate, label) {
    const { headlines } = this.props;

    const filteredData = headlines.filter(
      (item) =>
        item.time > startDate &&
        item.sentiment !== 0 &&
        item.title.indexOf(label) > -1
    );
    return filteredData && filteredData.length > 0 ? filteredData : null;
  }

  render() {
    const { toggled, hoveredChip } = this.state;
    const { chips = [], chipSelections = [], toggleChip, classes } = this.props;

    const limit = toggled ? 22 : 8;
    const allSelected = chips.filter(
      (item) => chipSelections.indexOf(item.text) > -1
    );
    const notSelected = chips.filter(
      (item) => chipSelections.indexOf(item.text) === -1
    );
    const ordered = allSelected.concat(notSelected);
    const limitedTags = ordered.slice(0, limit);

    return (
      <div className={classes.root}>
        <div>
          {allSelected.length > 0 && (
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={classes.clearButton}
              onClick={() => {
                toggleChip([]);
              }}
            >
              <ClearIcon />
            </Button>
          )}
        </div>

        {limitedTags.map((item, i) => {
          const label = item.text;
          const selected = chipSelections.indexOf(label) > -1;
          const colors = getChipColors(chipSelections);

          const last24hr = this.limitByTime(
            moment().subtract(24, "hour").unix(),
            label
          );
          const last7days = this.limitByTime(
            moment().subtract(7, "day").unix(),
            label
          );
          const last30days = this.limitByTime(
            moment().subtract(30, "day").unix(),
            label
          );

          return (
            <div
              className={classes.chipWrapper}
              onMouseEnter={() => {
                this.setHovered(label);
              }}
              onMouseLeave={() => {
                this.unsetHovered();
              }}
            >
              <Chip
                size="small"
                count={item.count}
                selected={selected}
                label={label}
                chipColor={selected ? colors(label) : null}
                deleteIcon={
                  selected ? <DoneIcon className={classes.icon} /> : null
                }
                onClick={(value) => toggleChip(value)}
                key={i + label}
              />
              <div className="sentenceWrapper">
                {(hoveredChip === label || selected) && (
                  <>
                    <div className="row">
                      <div className="column">
                        <div className={classes.wrapper}>
                          <div>Mentions</div>
                          <Sparkline chipSelection={label} />
                        </div>
                        <div className={classes.wrapper}>
                          <div>Context</div>
                          <SentenceList chipSelections={[label]} />
                        </div>
                      </div>
                      <div
                        className={`${classes.wrapper} ${classes.pieWrapper}`}
                      >
                        <div>Sentiment</div>
                        {last24hr && (
                          <>
                            <p>24 hours</p>
                            <Pie chipSelection={label} headlines={last24hr} />
                          </>
                        )}
                        {last7days && (
                          <>
                            <p>7 days</p>
                            <Pie chipSelection={label} headlines={last7days} />
                          </>
                        )}
                        {last30days && (
                          <>
                            <p>30 days</p>
                            <Pie chipSelection={label} headlines={last30days} />
                          </>
                        )}
                      </div>
                      <div
                        className={`${classes.wrapper} ${classes.pieWrapper}`}
                      >
                        <div>Related</div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          );
        })}
        <button
          className={classes.button}
          onClick={() => this.setToggled(!toggled)}
        >
          {toggled ? "[...less]" : "[... more]"}
        </button>
      </div>
    );
  }
}

const structuredSelector = createStructuredSelector({
  headlines: (state) => state.headlines.selectedHeadlines,
  chips: (state) => state.headlines.chips,
  chipSelections: (state) => state.headlines.chipSelections,
});

const mapDispatchToProps = { toggleChip };
export default connect(
  structuredSelector,
  mapDispatchToProps
)(withStyles(styles)(WordCloud));
