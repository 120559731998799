import React from "react";

import "./styles.scss";

export default function ForceDirectedGraph(props) {
  return (
    null || (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className="loader"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" stroke="none">
          <animateTransform
            attributeName="transform"
            type="rotate"
            dur="0.20964360587002098s"
            repeatCount="indefinite"
            keyTimes="0;1"
            values="0 50 51;360 50 51"
          ></animateTransform>
        </path>
        <text x="20" y="58" className="small">
          loading
        </text>
      </svg>
    )
  );
}
