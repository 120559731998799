import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { NGrams } from "natural";
import percentile from "percentile";
import countBy from "lodash/countBy";
import intersection from "lodash/intersection";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import { themeColors } from "../../constants/colors";

const rowSize = 28;

const styles = (theme) => ({
  root: {
    textAlign: "left",
    listStyleType: "none",
    padding: 0,
    maxWidth: "200px",
  },
  li: {
    marginBottom: 10,
    fontSize: "15px",
    color: themeColors.textBody,
    display: "flex",
    justifyContent: "space-between",
  },
  textSpan: {
    maxWidth: "150px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "inline-block",
  },
  count: {
    fontSize: "14px",
    color: themeColors.textMeta,
    lineHeight: "20px",
  },
});

class SentenceListComponent extends PureComponent {
  getFullHeeadlines(ngram) {}

  render() {
    const { chipSelections, filteredHeadlines, classes } = this.props;

    let newXgrams = [];
    if (chipSelections && filteredHeadlines) {
      let xgrams = NGrams.ngrams(
        filteredHeadlines.map((item) => item.title).join(" "),
        5
      )
        .filter((item) => intersection(item, chipSelections).length > 0)
        .map((item) => item.join(" "));

      xgrams = countBy(xgrams);

      var xgramsOrdered = Object.keys(xgrams).sort(function (a, b) {
        return xgrams[b] - xgrams[a];
      });

      xgramsOrdered.forEach((item) =>
        newXgrams.push({ ngram: item, count: xgrams[item] })
      );

      const result = percentile(99, newXgrams, (item) => item.count);

      if (result && result.count) {
        const threshold = result.count;
        newXgrams = newXgrams.filter((item) => item.count > threshold);
      }

      return (
        <ul className={classes.root}>
          {newXgrams.splice(0, 7).map((item) => {
            return (
              <li className={classes.li}>
                <span className={classes.textSpan}>{item.ngram}</span>
                <span className={classes.count}>({item.count})</span>
              </li>
            );
          })}
        </ul>
      );
    } else {
      return null;
    }
  }
}

const structuredSelector = createStructuredSelector({
  filteredHeadlines: (state) => state.headlines.filteredHeadlines,
});

const mapDispatchToProps = {};
export default connect(
  structuredSelector,
  mapDispatchToProps
)(withStyles(styles)(SentenceListComponent));
