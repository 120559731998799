import * as d3 from "d3";

// export const sentimentColors = [
//   d3.hcl("#46970F"),
//   d3.hcl("#87D652"),
//   d3.hcl("#E1B85F"),
//   d3.hcl("#F25D71"),
//   d3.hcl("#990729"),
// ];



export function getChipColors(chipSelections) {
  return d3.scaleOrdinal(d3.schemeTableau10).domain(chipSelections)
}

export const sentimentColors = [
  // positive
  d3.hcl("#46970f"),
  d3.hcl("#6f9f19"),
  d3.hcl("#6f9f19"),
  // neutral
  d3.hcl("#6fa19a"),
  // negative
  d3.hcl("#ac3530"),
  d3.hcl("#ac3530"),
  d3.hcl("#990729"),
];

export const sentimentColorsPie = [
  d3.hcl("#5ead2b"),
  d3.hcl("#b0253b"),
  d3.hcl("#6fa19a"),
  // "#46970F",
  // "#990729",
  // "#E1B85F",
];

export function sentimentColorRange(max, min) {
  const scale = d3
    .scaleLinear()
    .domain([min, min / 2, min / 4, 0, max / 4, max / 2, max])
    .range(sentimentColors)
    .interpolate(d3.interpolateLab);

  return scale;
}

export const themeColors = {
  backgroundBody: '#292a35',
  backgroundButton: '#45505d',
  backgroundButtonHover: '#5e7fa8',
  backgroundButtonActive: '#ff9b00',
  backgroundInput: '#191a24',
  backgroundFooter: '#1f1f28',

  strokeTable: '#585d6b',
  strokeCell: 'rgba(122,138,157, .3)',
  strokePanel: '#45505d',

  textBody: '#d9d7d7',
  textLinkHover: '#FFFFFF',
  textInput: '#8fa7c5',
  textButton: '#FFFFFF',
  textMeta: '#7d8c9f',

  iconFill: '#FFFFFF',
}

// export const themeColors = {
//   backgroundBody: '#292a35',
//   backgroundButton: '#45505d',
//   backgroundButtonHover: '#5e7fa8',
//   backgroundButtonActive: '#5e7fa8',
//   backgroundInput: '#191a24',
//   backgroundFooter: '#1f1f28',

//   strokeTable: '#7a8a9d',
//   strokeCell: 'rgba(122,138,157, .3)',
//   strokePanel: '#45505d',

//   textBody: '#FFFFFF',
//   textInput: '#8fa7c5',
//   textButton: '#FFFFFF',
//   textMeta: '#7d8c9f',

//   iconFill: '#FFFFFF',
// }