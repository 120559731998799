import natural from "natural";

const language = "EN";
const defaultCategory = "N";
const defaultCategoryCapitalized = "NNP";
var lexicon = new natural.Lexicon(
  language,
  defaultCategory,
  defaultCategoryCapitalized
);
var ruleSet = new natural.RuleSet("EN");

export const tagger = new natural.BrillPOSTagger(lexicon, ruleSet);

var TfIdf = natural.TfIdf;

export const tfidf = new TfIdf();;