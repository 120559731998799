import React, { PureComponent } from "react";
import * as d3 from "d3";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import moment from "moment";
import uniqueId from "lodash/uniqueId";

import { sentimentColorsPie, themeColors } from "../../constants/colors";

import countBy from "lodash/countBy";
import orderBy from "lodash/orderBy";

const styles = (theme) => ({
  wrapper: {
    width: "100%",
    padding: "10px",
  },
  pie: {
    width: "80px",
    margin: "0 auto",
  },
});

function getAllChipHeadlines(chip, headlines) {
  return headlines.filter((article) => article.title.indexOf(chip) > -1);
}

function buildChart(props, state) {
  const { classes, chipSelection, headlines } = props;
  const { uniqueId } = state;

  const rawData = getAllChipHeadlines(chipSelection, headlines);
  const mappedData = {
    positive: 0,
    negative: 0,
    neutral: 0,
  };

  rawData.forEach((item) => {
    if (item.sentiment > 0) {
      mappedData.positive++;
    } else if (item.sentiment < 0) {
      mappedData.negative++;
    } else {
      mappedData.neutral++;
    }
  });

  const data = [
    { name: "positive", value: mappedData.positive },
    { name: "negative", value: mappedData.negative },
    // { name: "neutral", value: mappedData.neutral },
  ];

  const color = d3
    .scaleOrdinal()
    .domain(data.map((d) => d.name))
    .range(sentimentColorsPie);

  const width = 75;
  const height = width;

  const arc = d3
    .arc()
    .innerRadius(0)
    .outerRadius(Math.min(width, height) / 2);

  const pie = d3
    .pie()
    .sort(null)
    .value((d) => d.value);

  const arcs = pie(data);

  const svg = d3
    .select(`#${uniqueId}`)
    .html(" ")
    .attr("viewBox", [-width / 2, -height / 2, width, height]);

  svg
    .append("g")
    .attr("stroke", themeColors.backgroundBody)
    .attr("stroke-width", "2px")
    .selectAll("path")
    .data(arcs)
    .join("path")
    .attr("fill", (d) => color(d.data.name))
    .attr("d", arc)
    .attr("opacity", 0.65)
    .on("mouseover", (event) => {
      d3.select(event.target).transition().duration(200).attr("opacity", 1);
    })
    .on("mouseout", (event) => {
      d3.select(event.target).transition().duration(200).attr("opacity", 0.65);
    })
    .append("title")
    .text((d) => `${d.data.name}: ${d.data.value.toLocaleString()}`);

  svg
    .append("g")
    .attr("font-family", `"Roboto Condensed", sans-serif`)
    .attr("text-anchor", "middle")
    .attr("font-size", "12px")
    .attr("fill", themeColors.textBody)
    .attr("fill-opacity", 0.7)
    .attr("style", "pointer-events: none")
    .selectAll("text")
    .data(arcs)
    .join("text")
    .attr("transform", (d) => `translate(${arc.centroid(d)})`)
    .call((text) =>
      text
        .filter((d) => d.endAngle - d.startAngle > 0.25)
        .append("tspan")
        .attr("x", 0)
        .attr("y", 0)
        .attr("fill-opacity", 0.7)
        .text((d) => d.data.value.toLocaleString())
    );

  const s = new XMLSerializer();
  return svg.node() ? s.serializeToString(svg.node()) : null;
}

class Pie extends PureComponent {
  state = {
    uniqueId: uniqueId("pie-"),
  };

  componentDidMount() {
    const { headlines } = this.props;

    if (headlines) {
      buildChart(this.props, this.state);
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.wrapper}>
        <svg className={classes.pie} id={this.state.uniqueId} />
      </div>
    );
  }
}

const structuredSelector = createStructuredSelector({
  // headlines: (state) => state.headlines.selectedHeadlines,
  chipSelectionData: (state) => state.headlines.chipSelectionData,
});

const mapDispatchToProps = {};
export default connect(
  structuredSelector,
  mapDispatchToProps
)(withStyles(styles)(Pie));
