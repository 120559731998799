import firebase from "firebase";
import difference from "lodash/difference";

import { readJSON, writeJSON } from "./persistence";

let db;
export async function init() {
  const firebaseConfig = {
    apiKey: "AIzaSyARsnzmStlc_MImno4N3m5fFlWZUh4REpg",
    authDomain: "newshacker-2fa2e.firebaseapp.com",
    projectId: "newshacker-2fa2e",
    storageBucket: "newshacker-2fa2e.appspot.com",
    messagingSenderId: "899874114865",
    appId: "1:899874114865:web:3d8d3a8c1cf21b71617410",
    measurementId: "G-3SG1BD3RJ2",
  };

  firebase.initializeApp(firebaseConfig);

  return firebase
    .auth()
    .signInAnonymously()
    .then(() => {
      console.log("AUTH: success");

      const analytics = firebase.analytics();

      db = firebase.database();

      if (window.location.hostname === "localhost") {
        // db.useEmulator("localhost", 9001);
      }
    })
    .catch((error) => {
      console.log("AUTH: fail");
      console.log(error);
    });
}

export function getSources() {
  return new Promise((resolve, reject) => {
    db.ref("sources")
      .get()
      .then((querySnapshot) => {
        const sources = querySnapshot.val();
        sources ? resolve(sources) : resolve([]);
      });
  });
}

// const promise1 = Promise.resolve(3);
// const promise2 = 42;
// const promise3 = new Promise((resolve, reject) => {
//   setTimeout(resolve, 100, 'foo');
// });

// Promise.all([promise1, promise2, promise3]).then((values) => {
//   console.log(values);
// });
// // expected output: Array [3, 42, "foo"]

export function getArticles(sources) {
  if (sources && sources.length > 0) {
    return new Promise((resolve, reject) => {
      const queuedCalls = [];
      sources.forEach((source) => {
        queuedCalls.push(getOneSource(source));
      });

      return Promise.all(queuedCalls).then((value) => resolve(value));
    });
  } else {
  }
}

// localstorage is limited to 10MB. This function can be used to replace
// getArticles with a layer of localstorage persistence
// it's not perfect - it doesnt check for synchro between persisted data
// and data in the DB
// TODO:
// - check for updates in remote data
// - check for existence of sources in the BE
// - error handling
// - if data mismatch, erase localstorage, proceed with fresh data
// - also clear sources if any sync error detected
export function getArticlesWithPersistence(sources) {
  const persistedSources = readJSON("sessionSources");
  const persistedHeadlines = readJSON("selectedHeadlines") || [];
  const persistedFetchedSources = persistedHeadlines.map((item) => item.source);
  const diffSources = difference(sources, persistedFetchedSources);

  if (persistedSources) {
  } else {
    writeJSON("sessionSources", sources);
  }

  if (diffSources && diffSources.length > 0) {
    return new Promise((resolve, reject) => {
      const queuedCalls = [];
      sources.forEach((source) => {
        queuedCalls.push(getOneSource(source));
      });

      return Promise.all(queuedCalls).then((value) => {
        if (persistedSources) {
          const combinedHeadlines = persistedHeadlines.concat(value);
          writeJSON("selectedHeadlines", combinedHeadlines);
        }
        resolve(value);
      });
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve(persistedHeadlines);
    });
  }
}

function getOneSource(source) {
  if (source === null) return;
  return new Promise((resolve, reject) => {
    db.ref(source)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.val();
        if (data) {
          let articles = Object.keys(data).map((key) => ({
            id: Number(key),
            ...data[key],
          }));
          resolve({ source: source, articles: articles });
        } else {
          reject([]);
        }
      });
  });
}
