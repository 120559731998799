import React from "react";
import * as d3 from "d3";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import flatMap from "lodash/flatMap";

import {
  sentimentColorRange,
  themeColors,
  getChipColors,
} from "../../constants/colors";

import countBy from "lodash/countBy";
import orderBy from "lodash/orderBy";

const useStyles = makeStyles((theme) => ({
  root: {
    float: "left",
  },
  wrapper: {
    width: "100%",
    float: "left",
    clear: "both",
  },
}));

function getAllChipHeadlines(chip, headlines) {
  return headlines.filter((article) => article.title.indexOf(chip) > -1);
}

function Sparkline(props) {
  const {
    headlines,
    chipSelection,
    chipSelections = [],
    chipSelectionData,
  } = props;
  const classes = useStyles();

  function buildChart() {
    const WIDTH = 550;
    const HEIGHT = 200;
    const MARGIN = { top: 5, right: 0, bottom: 5, left: 5 };
    const INNER_WIDTH = WIDTH - MARGIN.left - MARGIN.right;
    const INNER_HEIGHT = HEIGHT - MARGIN.top - MARGIN.bottom;

    const chipData = [];
    chipSelections.forEach((chippy) => {
      let dataPoints = [];
      getAllChipHeadlines(chippy, headlines)
        .sort((a, b) => a.time - b.time)
        .forEach((item, i) => {
          dataPoints.push({
            time: moment.unix(item.time).utc(),
            cumulative: i,
          });
        });
      chipData.push({
        label: chippy,
        data: dataPoints,
      });
    });

    if (!chipData || chipData.length === 0) return;

    const x = d3
      .scaleUtc()
      .domain(
        d3.extent(
          flatMap(chipData, (item) => item.data),
          (d) => d.time
        )
      )
      .range([MARGIN.left, INNER_WIDTH]);
    const yMax = d3.max(chipData, (d) => d3.max(d.data, (c) => c.cumulative));
    const y = d3
      .scaleLinear()
      .domain([0, yMax])
      .range([INNER_HEIGHT, MARGIN.top]);

    const z = getChipColors(chipData.map((d) => d.label));

    const svg = d3
      .create("svg")
      .attr("viewBox", [0, 0, WIDTH, HEIGHT])
      .attr("width", WIDTH)
      .attr("height", HEIGHT);
    const line = d3
      .line()
      .curve(d3.curveLinear)
      .x((d) => x(d.time))
      .y((d) => y(d.cumulative));

    var lg = svg
      .append("defs")
      .append("linearGradient")
      .attr("id", "areaGradient") //id of the gradient
      .attr("x1", "0%")
      .attr("x2", "0%")
      .attr("y1", "0%")
      .attr("y2", "100%"); //since its a vertical linear gradient

    lg.append("stop")
      .attr("offset", "0%")
      .style("stop-color", "rgba(94, 127, 168, 1)")
      .style("stop-opacity", 0.1);

    lg.append("stop")
      .attr("offset", "100%")
      .style("stop-color", "rgba(94, 127, 168, 1)")
      .style("stop-opacity", 0);

    const area = d3
      .area()
      .x((d) => x(d.time))
      .y0(y(0))
      .y1((d) => y(d.cumulative));

    // Add the area
    svg
      .append("g")
      .selectAll("path")
      .data(chipData)
      .enter()
      .append("path")
      .style("fill", "url(#areaGradient)") //id of the gradient for fill
      .attr("d", (d) => area(d.data));

    // Add line
    const lines = svg
      .append("g")
      .selectAll("path")
      .data(chipData)
      .enter()
      .append("path")
      .attr("fill", "none")
      .attr("stroke", (d) => z(d.label))
      .attr("stroke-width", 1)
      .attr("d", (d) => line(d.data));

    const circles = svg
      .append("g")
      .selectAll("circle")
      .data(chipData)
      .enter()
      .append("circle")
      .attr("r", 2)
      .attr("cx", (d) => x(d.data[d.data.length - 1].time))
      .attr("cy", (d) => y(d.data[d.data.length - 1].cumulative))
      .attr("fill", (d) => z(d.label));

    const s = new XMLSerializer();
    return svg.node() ? s.serializeToString(svg.node()) : null;
  }

  if (headlines && chipSelections.length > 0) {
    const chart = buildChart();

    return (
      <div className={classes.wrapper}>
        {chart && (
          <div
            className={classes.root}
            dangerouslySetInnerHTML={{ __html: buildChart() }}
          />
        )}
      </div>
    );
  } else {
    return null;
  }
}

const structuredSelector = createStructuredSelector({
  headlines: (state) => state.headlines.filteredHeadlines,
  chipSelectionData: (state) => state.headlines.chipSelectionData,
  chipSelections: (state) => state.headlines.chipSelections,
});

const mapDispatchToProps = {};
export default connect(structuredSelector, mapDispatchToProps)(Sparkline);
