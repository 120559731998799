import { call, put, takeLatest, all, select } from "redux-saga/effects";
import * as d3 from "d3";
import orderBy from "lodash/orderBy";
import flatMap from "lodash/flatMap";

import { getArticles, getSources } from "../services/firestore";
import * as selectors from "../selectors/selectors";
import { Sources, Headlines } from "../constants/constants";
import { readJSON, writeJSON } from "../services/persistence";

import {
  fetchHeadlinesSuccess,
  fetchSourcesSuccess,
  selectSources,
  selectHeadlines,
  createChips,
  updateFilters,
  updateSelectedHeadlinesDateRange,
  updateFilteredHeadlines
} from "../actions/headlines";

import {
  fetchingData,
  fetchingDataFailed,
  fetchingDataSuccess,
} from "../actions/global";

function* getSourcesSaga() {
  const sources = yield getSources().then((data) => {
    return data;
  });
  const sessionSources = readJSON("sessionSources");

  yield put(fetchSourcesSuccess(sources));

  // get localstorage sources or load first one in list
  yield put(selectSources(sessionSources ? sessionSources : [sources[0]]));
}

function* getHeadlinesSaga() {
  // getArticles
}

function* handleUpdatedSources() {
  yield put(fetchingData());
  try {
    const headlines = yield select(selectors.headlines);
    const selectedSources = yield select(selectors.selectedSources);
    let headlinesToGet;

    // stop execution if no sources selected
    if (
      typeof selectedSources === "undefined" ||
      selectedSources.length === 0 ||
      typeof selectedSources === "string" ||
      selectedSources instanceof String
    ) {
      yield put(fetchingDataFailed());
      yield put(updateFilteredHeadlines([]))
      return;
    }

    // which headlines have not been fetched before
    if (headlines) {
      const currentHeadlines = headlines.map((item) => item.source);
      headlinesToGet = selectedSources.filter(
        (item) => currentHeadlines.indexOf(item) === -1
      );
    } else {
      headlinesToGet = selectedSources;
    }

    // all headlines have been fetched before
    if (headlines && headlines.length > 0 && headlinesToGet.length === 0) {
      const newFlatSources = yield flattenAllSources();
      yield put(selectHeadlines(newFlatSources));
      yield put(createChips());
    } else if (headlines && headlines.length > 0 && selectedSources) {
      // fetch new headlines that havent been cached before
      if (headlinesToGet.length > 0) {
        const articles = yield getArticles(headlinesToGet).then((data) => {
          return data;
        });

        yield put(fetchHeadlinesSuccess(articles));
      }

      const newFlatSources = yield flattenAllSources();
      yield put(selectHeadlines(newFlatSources));
      yield put(createChips());
    } else {
      // no headlines have been fetched before - fetch as per sources
      const articles = yield getArticles(selectedSources).then((data) => {
        return data;
      });
      yield put(fetchHeadlinesSuccess(articles));

      const newFlatSources = yield flattenAllSources();
      yield put(selectHeadlines(newFlatSources));
      yield put(createChips());
    }

    // persist selected sources in local storage
    writeJSON("sessionSources", selectedSources);
    yield call(updateFiltersSaga);
  } catch (err) {
    yield put(fetchingDataFailed());
    console.error(err);
  }
}

function* flattenAllSources() {
  const headlines = yield select(selectors.headlines);
  const selectedSources = yield select(selectors.selectedSources);

  let selectedHeadlines = flatMap(
    headlines
      .filter(
        (headlinesList) => selectedSources.indexOf(headlinesList.source) > -1
      )
      .map((item) => item.articles)
  );

  yield put(
    updateSelectedHeadlinesDateRange(
      d3.extent(selectedHeadlines, (item) => item.time)
    )
  );
  return selectedHeadlines;
}

function* updateFiltersSaga() {
  yield put(fetchingData());
  const chipFilters = yield select(selectors.chipSelections);
  const dateFilter = yield select(selectors.dateFilter);
  let newSelectedHeadlines = yield select(selectors.selectedHeadlines);

  // date selection filtering
  if (dateFilter && dateFilter.length > 0) {
    const dateHeadlines = newSelectedHeadlines.filter(
      (article) =>
        parseInt(article.time) > dateFilter[0] &&
        parseInt(article.time) < dateFilter[1]
    );
    if (dateHeadlines.length > 0) {
      newSelectedHeadlines = dateHeadlines;
    }
  }

  // find only headlines with selected chips if any
  if (chipFilters && chipFilters.length > 0) {
    newSelectedHeadlines = newSelectedHeadlines.filter(
      (article) =>
        article.title
          .split(" ")
          .filter((word) => chipFilters.indexOf(word) > -1).length > 0
    );
  }

  yield put(
    updateFilters({
      filteredHeadlines: newSelectedHeadlines,
      lowestSentimentArticles: orderBy(
        newSelectedHeadlines,
        ["sentiment"],
        ["asc"]
      ).splice(0, 10),
      highestSentimentArticles: orderBy(
        newSelectedHeadlines,
        ["sentiment"],
        ["desc"]
      ).splice(0, 10),
      highestScoreArticles: orderBy(
        newSelectedHeadlines,
        ["score"],
        ["desc"]
      ).splice(0, 100),
    })
  );
  yield put(fetchingDataSuccess());
}

function* handleSelectHeadlines() {
  const chipSelections = yield select(selectors.chipSelections);

  if (!chipSelections || chipSelections.length == 0) {
    yield put(createChips());
  }
  yield call(updateFiltersSaga);
}

function* handleChipChange() {
  yield call(updateFiltersSaga);
}

function* handleDatesChange() {
  yield call(updateFiltersSaga);
}

export default function* rootSaga() {
  yield all([
    takeLatest(Sources.fetchSources, getSourcesSaga),
    takeLatest(Sources.selectSources, handleUpdatedSources),
    takeLatest(Headlines.selectHeadlines, handleSelectHeadlines),
    takeLatest(Headlines.toggleChip, handleChipChange),
    takeLatest(Headlines.changeDates, handleDatesChange),
  ]);
}
